<template lang="pug">

div.upl
	input.hidden(type="file", v-on:change="showFile", multiple="multiple", v-bind:ref="'filesselect'" )
	#file-drag-drop
		form.form1(ref="fileform1", v-on:click="showFilesSelectDialogue")
			span файлы для загрузки бросать здесь 
		form.form2(ref="fileform2", v-on:click="showFilesSelectDialogue")
			span или кликнуть здесь и выбрать
		div.upl_ul(ref='upl_ul')
			div.upl_li(v-for="t of $store.getters['fuploader/GETQ']")
				div.upl_li_caption
					div.upl_li_caption_img
						img.preview( v-bind:ref="'preview'+t.id", v-bind:key="'img'+t.id")
					div.upl_li_caption_text
						| &vrtri; 
						| {{t.file.name}}  ( {{ Math.round(t.file.size / (1024*1024)) }} Mb ) [{{t.i}} of {{t.file.size}}] {{Math.round((t.i/t.file.size)*100)}}%
				div.upl_li_bg(v-bind:style="'width:' + Math.round((t.i/t.file.size)*100) +'%'")
				div.upl_li_ctl
					div.upl_li_pc.none(v-if="t.done=='none'") ...
					div.upl_li_pc.pr(v-if="t.done=='progress'") &circlearrowleft;
					div.upl_li_pc.pr(v-if="t.done=='paused'") P
					div.upl_li_pc.done(v-if="t.done=='done'") &check;
					div.upl_li_pc.err(v-if="t.done=='err'") &Chi;
					div.upl_li_pc.err(v-if="t.done=='fordelete'") &Chi;
					button.btn.btn-danger.btn-xs(v-on:click="stopTask( t )") &times;
</template>

<script lang='js'>
	export default {
		data(){
			return {
				dragAndDropCapable: false,
				files: []
			}
		},
		props:{
			argv: Object
		},
		mounted(){
			this.$store.commit('fuploader/INIT', this);
			this.dragAndDropCapable = this.determineDragAndDropCapable();
			if( this.dragAndDropCapable ){
				const forms = ['fileform1','fileform2', 'upl_ul'];
				['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
					forms.forEach( function( frm ){
						this.$refs[frm].addEventListener(evt, function(e){
							e.preventDefault();
							e.stopPropagation();
						}.bind(this), false);
					}.bind(this))
				}.bind(this));
				forms.forEach( function(frm) {
					this.$refs[ frm ].addEventListener('drop', function(e){
						for( let i = 0; i < e.dataTransfer.files.length; i++ ){
							let file = e.dataTransfer.files[i];
							this.$store.dispatch('fuploader/FILE_ADD', {file, argv:this.argv});
						}
						this.$store.dispatch('fuploader/START');
						this.$store.dispatch('fuploader/GETIMAGEPREVIEWS');
					}.bind(this));
				}.bind(this));
			}
		},
		methods:{
			showFilesSelectDialogue(){
				this.$refs.filesselect.value = null;
				this.$refs.filesselect.click();
			},
			getImagePreviews(){
				for( let task of this.$store.getters['fuploader/GETQ'] ){
					this.$nextTick(function(){
						try{
							this.$refs['preview' + task.id][0].src = '/img/file.jpg';
						}catch(e){
						}
					});
					/* 
					// если хочетс превьюшек
					if ( /\.(jpe?g|png|gif)$/i.test( task.file.name ) ) {
						try{
							let reader = new FileReader();
							reader.addEventListener("load", function(){
								(this.$refs['preview'+task.id ] || [{}] )[0].src = reader.result;
							}.bind(this), false);
							reader.readAsDataURL( task.file );
						}catch(e){
							this.$nextTick(function(){
								this.$refs['preview' + task.id][0].src = '/img/file.jpg';
							});
						}
					} else {
						this.$nextTick(function(){
							this.$refs['preview' + task.id][0].src = '/img/file.jpg';
						});
					}
					*/
				}
			},
			determineDragAndDropCapable(){
				var div = document.createElement('div');
				return ( ( 'draggable' in div )
							|| ( 'ondragstart' in div && 'ondrop' in div ) )
							&& 'FormData' in window
						&& 'FileReader' in window;
			},
			async showFile(event) {
				for( let file of event.target.files ){
					this.$store.dispatch('fuploader/FILE_ADD', {file, argv: this.argv});
				}
				this.$store.dispatch('fuploader/START');
				this.$store.dispatch('fuploader/GETIMAGEPREVIEWS');
			},
			async stopTask(t){
				this.$store.dispatch('fuploader/STOPTASK', t);
			}
		}
	}
</script>

<style>
	input.hidden{
		display: none;
	}
	div.upl{
		/* background-color: #fff; */
		background-color: #fff;
		border: dotted #eee 2px;
		border-radius: 6px;
		padding: 2px;
	}
	div.upl_li{
		background-color: #fff;
		position: relative;
		height: 45px;
		margin-top: 5px;
	}
	.upl_li_ctl .none{
		color: #568dcd;
		font-size: 15px !important;
	}
	.upl_li_ctl .pr{
		font-size: 20px !important;
	}
	.upl_li_ctl .done{
		color: green;
		font-size: 20px !important;
	}
	.upl_li_ctl .err{
		color: rgb(155, 2, 2);
		font-size: 20px !important;
	}
	div.upl_li_bg{
		background-color: rgb(175, 175, 175);
		height: 5px;
	}
	div.upl_li_caption{
		padding-left: 0px;
		height: 26px;
		font-size: 13px;
		width: 100%;
		overflow: hidden;
	}
	div.upl_li_caption_img{
		position:absolute;
		top:0px;
		left:0px;
		width: 35px;
		overflow: hidden;
	}
	div.upl_li_caption_text{
		padding-left: 40px;
		overflow: hidden;
	}
	div.upl_li_ctl{
		padding: 0px;
		position: absolute;
		right: 0px;
		top: 0px;
		background-color: #cde;
		width: 62px;
		height: 24px;
		line-height: 20px;
		padding-top: 1px;
		padding-bottom: 1px;
	}
	div.upl_li_pc{
		font-size: 5px;
		width: 50%;
		float: left;
		padding-left: 2px;
		line-height: 23px;
		text-align: center;
	}
	div.upl_li_ctl button{
		display: inline-block;
		height: 20px;
		line-height: 15px;
		width: 40%;
		padding: 1px;
		margin: 0px;
	}
	form{
		display: block;
		/* height: 20px; */
		width: 100%;
		background: #ccc;
		margin: auto;
		text-align: center;
		line-height: 20px;
		cursor: pointer;
		font-size: 14px;
	}
	.form1{
		border-radius: 4px 4px 0px 0px ;
	}
	.form2{
		border-radius: 0px 0px 4px 4px ;
	}
	.preview{
		height: 23px;
	}
</style>
