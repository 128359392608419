<template lang="pug">
div.container
	mmenu
	h3 Журнал сообщений
	
	div &nbsp;
	tabs(v-bind:activeTab='activeTab', v-on:newtab='newtab')
		tab( name='Журнал текущего сеанса' )
			//-- pre {{  $store.getters['log/MESSAGES'] }}
			ul
				li(v-for="rec of $store.getters['log/MESSAGES']")  {{dt_h(rec.dt)}}  [ {{rec.type}} ][ {{rec.login}} ]  {{rec.message}}
		//-- tab( name='Общий журнал' )

</template>

<script lang='js'>
	import moment from 'moment';
	import mmenu from './mmenu.vue';
	import tabs from './tabs'
	import tab from './tab'
	export default {
		data: function(){
			return {
				activeTab:0
			}
		},
		components: {
			mmenu,
			tabs,
			tab
		},
		created: function(){
			if(!this.$store.getters['login/LOGGEDIN']) this.$router.push('/login');
		},
		computed: {
			messages_length(){
				return this.$store.getters['log/MESSAGESLENGTH'];
			}
		},
		watch:{
			messages_length(newVal, oldVal) {
				if( newVal>0 ){
					let mess = this.$store.getters['log/MESSAGE'];
					if( mess.type=='error' ){
						this.$swal(mess.title, mess.message, mess.type);
					}
				}
			}
		},
		methods:{
			dt_h(dt){
				return moment(dt).format('YYYY-MM-DD HH:mm:ss')
			},
			newtab: function(n){
				this.activeTab = n;
			}
		}
	}
</script>

<style>
</style>

