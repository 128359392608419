var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("mmenu"),
      _vm._m(0),
      _c("div", [_vm._v(" ")]),
      _c(
        "div",
        { staticStyle: { "line-height": "20px", "font-size": "16px" } },
        [
          _c(
            "a",
            {
              staticClass: "pointer",
              on: {
                click: function($event) {
                  return _vm.clickpath(0)
                }
              }
            },
            [
              _c("img", {
                staticStyle: { "margin-top": "-3px" },
                attrs: { src: "/img/folder_home.png" }
              })
            ]
          ),
          _c("span", { staticClass: "pl5" }, [_vm._v("/")]),
          _vm._l(_vm.$store.getters["pub/PATH"], function(p, index) {
            return _c("span", [
              _c(
                "span",
                {
                  staticClass: "aslink pointer",
                  on: {
                    click: function($event) {
                      return _vm.clickpath(index + 1)
                    }
                  }
                },
                [_vm._v(_vm._s(p))]
              ),
              _c("span", [_vm._v("/")])
            ])
          })
        ],
        2
      ),
      _c("div", [_vm._v(" ")]),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-light shadow2 right10",
          on: {
            click: function($event) {
              return _vm.createfolder()
            }
          }
        },
        [
          _c("img", {
            staticClass: "mr10",
            attrs: { src: "/img/newfolder.png" }
          }),
          _vm._v("Создать папку")
        ]
      ),
      _vm._v("   "),
      _vm.$store.getters["pub/PATH"].length > 0
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light shadow2",
              attrs: {
                id: "popover",
                title: "О, кстати!",
                "data-content":
                  "Можно бросить файл на эту кнопку, чтобы переместить его в родительскую папку!",
                "data-trigger": "manual"
              },
              on: {
                click: function($event) {
                  return _vm.clickfolder({ level: -1 })
                }
              }
            },
            [
              _c(
                "drop",
                {
                  on: {
                    drop: function(data, event) {
                      _vm.dropfiles(data, { name: ".." }, event)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "mr10",
                    attrs: { src: "/img/up.png", height: "16" }
                  }),
                  _vm._v("Наверх")
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v("   "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-light shadow2 right10",
          on: {
            click: function($event) {
              return _vm.yt_dlp()
            }
          }
        },
        [
          _c("img", {
            staticClass: "mr10",
            attrs: { src: "/img/newfolder.png" }
          }),
          _vm._v("Загрузить с Youtube")
        ]
      ),
      _c("div", [_vm._v(" ")]),
      _c("div", { staticClass: "hide" }, [
        _c("textarea", { attrs: { id: "ta" } }, [_vm._v(_vm._s(_vm.clipboard))])
      ]),
      _c("vue-context", {
        ref: "menu",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var data = ref.data
              return [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.rm(data)
                        }
                      }
                    },
                    [_vm._v("Удалить")]
                  )
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.mv(data)
                        }
                      }
                    },
                    [_vm._v("Переименовать")]
                  )
                ]),
                !(data && data.isfolder) ? _c("li", [_c("hr")]) : _vm._e(),
                !(data && data.isfolder)
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            id: "cc",
                            href: "#",
                            "data-clipboard-target": "textarea#ta"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.copyURL(data)
                            }
                          }
                        },
                        [_vm._v("Копировать URL")]
                      )
                    ])
                  : _vm._e(),
                !(data && data.isfolder)
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { id: "cc", href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showURL(data)
                            }
                          }
                        },
                        [_vm._v("Показать URL")]
                      )
                    ])
                  : _vm._e(),
                !(data && data.isfolder)
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.goto(data)
                            }
                          }
                        },
                        [_vm._v("Открыть во вкладке")]
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("table", [
        _c(
          "tbody",
          _vm._l(_vm.$store.getters["pub/FOLDERS"], function(i) {
            return _c("tr", [
              _c(
                "td",
                {
                  staticClass: "icon pointer",
                  on: {
                    click: function($event) {
                      return _vm.clickfolder(i)
                    }
                  }
                },
                [
                  _c(
                    "drop",
                    {
                      on: {
                        drop: function(data, event) {
                          _vm.dropfiles(data, i, event)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/img/folder.png" },
                        on: {
                          contextmenu: function($event) {
                            $event.preventDefault()
                            return _vm.$refs.menu.open(
                              $event,
                              Object.assign({}, i, { isfolder: true })
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "td",
                {
                  staticClass: "pointer pl5",
                  on: {
                    click: function($event) {
                      return _vm.clickfolder(i)
                    }
                  }
                },
                [
                  _c(
                    "drop",
                    {
                      on: {
                        drop: function(data, event) {
                          _vm.dropfiles(data, i, event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            contextmenu: function($event) {
                              $event.preventDefault()
                              return _vm.$refs.menu.open(
                                $event,
                                Object.assign({}, i, { isfolder: true })
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(i.name))]
                      )
                    ]
                  )
                ],
                1
              ),
              _c("td"),
              _c("td", { staticClass: "right dt pr5" }, [
                _vm._v(_vm._s(i.stats.mtime))
              ])
            ])
          }),
          0
        ),
        _c(
          "tbody",
          _vm._l(_vm.$store.getters["pub/FILES"], function(i) {
            return _c("tr", { key: i.key }, [
              _c("td", { staticClass: "icon pointer" }, [
                _c("img", {
                  attrs: { src: "/img/file.png" },
                  on: {
                    contextmenu: function($event) {
                      $event.preventDefault()
                      return _vm.$refs.menu.open($event, i)
                    },
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.turnURL(i)
                    }
                  }
                })
              ]),
              !i.showurl
                ? _c(
                    "td",
                    [
                      _c(
                        "drag",
                        {
                          attrs: { "transfer-data": { name: i.name } },
                          on: { dragstart: _vm.dragstart, dragend: _vm.dragend }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.makelink(i.name),
                                download: "",
                                target: "_blank"
                              },
                              on: {
                                contextmenu: function($event) {
                                  $event.preventDefault()
                                  return _vm.$refs.menu.open($event, i)
                                }
                              }
                            },
                            [_vm._v(_vm._s(i.name) + " " + _vm._s(i.showurl))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              i.showurl
                ? _c("td", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.makelink(i.name),
                          download: "",
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.makelink(i.name)))]
                    )
                  ])
                : _vm._e(),
              _c(
                "td",
                { staticClass: "right", attrs: { title: i.stats.size } },
                [_vm._v(_vm._s(i.stats.size_h))]
              ),
              _c("td", { staticClass: "right dt pr5" }, [
                _vm._v(_vm._s(i.stats.mtime))
              ])
            ])
          }),
          0
        )
      ]),
      _c("center"),
      _c("div", [_vm._v(" ")]),
      _c("fuploader", {
        attrs: {
          argv: { path: _vm.$store.getters["pub/PATH"], dst: "pub/ADDFILE" }
        }
      }),
      _c("div", [_vm._v(" ")])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-3" }, [
        _c("h3", [_vm._v("Обмен файлами")])
      ]),
      _c("div", { staticClass: "col-md-9 right" }, [
        _c("a", { attrs: { href: "/files" } }, [_vm._v("старый интерфейс")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }