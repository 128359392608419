<template lang="pug">
div
	.tabs
		ul
			li(v-for="tab in tabs", v-bind:class="{ 'is-active': tab.isActive }", v-bind:key="tab.name")
				a(v-on:click="selectTab(tab)") {{ tab.name }}
	.tabs-details
		slot
</template>

<script lang='js'>
	export default {
		data(){
			return {
				tabs: []
			}
		},
		props:['activeTab'],
		watch:{
			activeTab: function(newval){
				this.tabs.forEach( tab =>{
					tab.isActive = false;
				})
				this.tabs[newval].isActive = true;
			}
		},
		created() {
			this.tabs = this.$children;
		},
		mounted() {
			this.tabs[this.activeTab].isActive = true;
		},
		methods: {
			selectTab(selectedTab) {
				let newtab = null;
				for( let i in this.tabs){
					if(this.tabs[i].name == selectedTab.name){
						this.tabs[i].isActive = true;
						newtab = i;
					}else{
						this.tabs[i].isActive = false;
					};
				}
				if( newtab != null){
					this.$emit( 'newtab', newtab);
				}
			}
		}
	}
</script>
