var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c(
      "form",
      {
        staticClass: "blank",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "jumbotron shadow",
            staticStyle: { margin: "100px auto", width: "450px" }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "col-md-3" }, [_vm._v("Логин:")]),
              _c("div", { staticClass: "col-md-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.login,
                      expression: "login"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.login },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.login = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "col-md-3" }, [_vm._v("Пароль:")]),
              _c("div", { staticClass: "col-md-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._m(1)
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("a", { attrs: { href: "/files" } }, [_vm._v("старый интерфейс")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary btn-sm",
            attrs: { type: "submit" }
          },
          [_vm._v("войти")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }