<template lang="pug">
div.container
	mmenu
	.row
		.col-md-3
			h3 Обмен файлами
		.col-md-9.right
			a(href='/files')
				| старый интерфейс
	div &nbsp;
	div(style='line-height: 20px; font-size: 16px;')
		a.pointer(v-on:click='clickpath(0)')
			img(src='/img/folder_home.png', style='margin-top: -3px;')
		span.pl5 /
		span(v-for="(p,index) of $store.getters['pub/PATH']")
			span.aslink.pointer(v-on:click='clickpath(index+1)') {{p}}
			span /

	div &nbsp;

	button.btn.btn-sm.btn-light.shadow2.right10(v-on:click='createfolder()')
		img.mr10(src='/img/newfolder.png')
		| Создать папку
	| &nbsp; &nbsp;
	button#popover.btn.btn-sm.btn-light.shadow2( v-if="$store.getters['pub/PATH'].length>0", v-on:click="clickfolder({level:-1})", title="О, кстати!", data-content="Можно бросить файл на эту кнопку, чтобы переместить его в родительскую папку!", data-trigger='manual')
		drop(@drop="function(data, event) { dropfiles(data, {name:'..'}, event);}")
			img.mr10(src='/img/up.png', height=16)
			| Наверх
	| &nbsp; &nbsp;
	button.btn.btn-sm.btn-light.shadow2.right10(v-on:click='yt_dlp()')
		img.mr10(src='/img/newfolder.png')
		| Загрузить с Youtube

	div &nbsp;

	.hide
		textarea#ta {{clipboard}}

	vue-context(ref="menu", v-slot="{ data }")
		li
			a(href="#", @click.prevent="rm(data)") Удалить
		li
			a(href="#", @click.prevent="mv(data)") Переименовать
		li(v-if='!(data && data.isfolder)')
			hr
		li(v-if='!(data && data.isfolder)')
			a#cc(href="#", data-clipboard-target="textarea#ta", @click.prevent="copyURL(data)") Копировать URL
		li(v-if='!(data && data.isfolder)')
			a#cc(href="#", @click.prevent="showURL(data)") Показать URL
		li(v-if='!(data && data.isfolder)')
			a(href="#", @click.prevent="goto(data)") Открыть во вкладке


	table
		tbody
			tr(v-for="i of $store.getters['pub/FOLDERS']")
				td.icon.pointer(v-on:click='clickfolder(i)')
					drop(@drop="function(data, event) { dropfiles(data, i, event);}")
						img(src='/img/folder.png', @contextmenu.prevent="$refs.menu.open($event, {...i, isfolder:true})")
				td.pointer.pl5(v-on:click='clickfolder(i)' )
					drop(@drop="function(data, event) { dropfiles(data, i, event);}")
						span(@contextmenu.prevent="$refs.menu.open($event, {...i, isfolder:true})") {{i.name}}
				td
				td.right.dt.pr5 {{i.stats.mtime}}
		tbody
			tr(v-for="i of $store.getters['pub/FILES']", v-bind:key='i.key')
				td.icon.pointer
					img(src='/img/file.png', @contextmenu.prevent="$refs.menu.open($event, i)", @click.prevent="turnURL(i)")
				td(v-if='!i.showurl')
					drag(:transfer-data="{name:i.name}", v-on:dragstart="dragstart", v-on:dragend="dragend")
						a(v-bind:href="makelink(i.name)", download,  target='_blank', @contextmenu.prevent="$refs.menu.open($event, i)") {{i.name}} {{i.showurl}}

				td(v-if='i.showurl')
					a(v-bind:href="makelink(i.name)", download,  target='_blank' ) {{makelink(i.name)}}

				td.right(:title='i.stats.size') {{i.stats.size_h}}
				td.right.dt.pr5 {{i.stats.mtime}}
	center

	div &nbsp;

	fuploader(:argv="{path:$store.getters['pub/PATH'], dst:'pub/ADDFILE'}")

	div &nbsp;

</template>

<script lang='js'>
	import { Drag, Drop } from 'vue-drag-drop';
	import fuploader from './fuploader';
	import VueContext from 'vue-context';
	import 'vue-context/src/sass/vue-context.scss';
	import mmenu from './mmenu.vue';
	import ClipboardJS from 'clipboard';
	export default {
		data: function(){
			return {
				clipboard:'123123'
			}
		},
		mounted: function(){
			this.$store.dispatch('pub/LOAD', {});
		},
		created: function(){
			if(!this.$store.getters['login/LOGGEDIN']) this.$router.push('/login');
			new ClipboardJS('a#cc');
		},
		components: {
			mmenu,
			fuploader,
			VueContext,
			Drag,
			Drop
		},
		computed: {
			messages_length(){
				return this.$store.getters['log/MESSAGESLENGTH'];
			}
		},
		watch:{
			messages_length(newVal, oldVal) {
				if( newVal>0 ){
					let mess = this.$store.getters['log/MESSAGE'];
					if( mess.type=='error' ) this.$swal(mess.title, mess.message, mess.type);
					if( mess.type=='alert' ) this.$swal(mess.title, mess.message, 'info');
				}
			}
		},
		methods:{
			dragend(){
				$("#popover").popover("hide");
			},
			dragstart(){
				$("#popover").popover("show");
			},
			dropfiles(data, folder, event){
				$("#popover").popover("hide");
				this.$store.dispatch('pub/MV', {name:data.name, newname:folder.name, moveiffolder:true});
			},
			getfileurl(obj){
				let path = [];
				path.push(document.location.protocol + '//' + document.location.host +'/files/')
				path.push(this.$store.getters['pub/PATH']
				.map( p => {
					return encodeURIComponent( p );
				})
				.join('/'));
				if(path[1] != '') path.push( '/' );
				path.push( encodeURIComponent( obj.name ) );
				return path;
			},

			showURL(obj){
				const wrapper = document.createElement('div');
				wrapper.innerHTML =  '<a href="'  + this.makelink(obj.name) + '">' + this.makelink(obj.name) + '</a>';
				this.$swal({title: 'Пожалуйста', content: wrapper });
			},

			turnURL(obj){
				if( obj.showurl ){
					this.$store.dispatch('pub/HIDEURL', {name: obj.name})
				}else{
					this.$store.dispatch('pub/SHOWURL', {name: obj.name})
				}
			},

			copyURL(obj){
				this.clipboard = this.getfileurl(obj).join('');
				//  this.getfileurl(obj).join('')
				try{
					navigator.clipboard.writeText(this.clipboard);
				}catch(e){}
				return true;
			},
			goto(obj){
				//-- document.location.href = this.getfileurl(obj).join('');
				window.open(this.getfileurl(obj).join(''));
			},
			clickpath(i){
				this.$store.dispatch('pub/CD', {level:i});
			},
			clickfolder(i){
				this.dragend();
				this.$store.dispatch('pub/CD', i);
			},
			makelink(name){
				return 'files/' + this.$store.getters['pub/PATH'].map( i =>{ return encodeURIComponent(i) }).join('/') + '/' + encodeURIComponent(name);
			},
			createfolder(){	
				this.$swal({
					content: {
						element: "input",
						attributes: {
							placeholder: "Имя новой папки",
							type: "text",
						},
					},
				})
				.then((value) => {
					//swal(`The returned value is: ${value}`);
					this.$store.dispatch('pub/MKDIR', {name:value});
				});
			},
			yt_dlp(){	
				this.$swal({
					content: {
						element: "input",
						attributes: {
							placeholder: "Ссылка Youtube/Rutube",
							type: "text",
						},
					},
				})
				.then((value) => {
					this.$store.dispatch('pub/YT_DLP', {url: value});
				});
			},
			rm(obj) {
				let th = this;
				this.$swal({
						title: "Уверены?",
						text: "Элемент будет удалён полностью вместе со вложенными элементами без возможности восстановить",
						icon: "warning",
						buttons: [
							'Нет, не удалять!',
							'Да, я уверен!'
						],
						dangerMode: true,
					})
				.then(function(isConfirm) {
					if (isConfirm) {
						th.$store.dispatch('pub/RM', {name: obj.name});
					}
				})
			},
			mv(obj) {
				let th = this;
				this.$swal({
					content: {
						element: "input",
						attributes: {
							placeholder: "Новое имя",
							defaultValue: obj.name,
							type: "text"
						},
					}
				})
				.then((value) => {
					if( value!=null ){
						if( value=='' ){
							th.$swal("Внимание!", "Новое имя не может быть пустым", "error");
						}else{
							th.$store.dispatch('pub/MV', {name:obj.name, newname:value});
						}
					}
				});
			}
		}
	}
</script>

<style>
	.right{
		text-align: right;
	}
	.yellow{
		color: #ffe587;
	}
	.gray{
		color: #eeeeee;
	}
	.shadow2{
		box-shadow: 0 0 5px rgba(0,0,0,0.3); 
	}
	td {
		background-color: #fff;
	}
	td.icon{
		width: 30px;
		text-align: center;
		cellspacing: 2px;
	}
	table {
		background-color: #eee;
		width: 100%; /* Ширина таблицы */
		border: 1px solid #eee; /* Граница вокруг таблицы */
		border-spacing: 0px 1px; /* Расстояние между границ */
		border-collapse: separate;
	}
	.pl10{
		padding-left: 10px;
	}
	.pr10{
		padding-right: 10px;
	}
	.pl5{
		padding-left: 5px;
	}
	.pr5{
		padding-right: 5px;
	}
	td.dt {
		width: 200px;
	}
	.aslink:hover {
		text-decoration: underline;
	}
	.mr10{
		margin-right: 10px;
	}
	.hide{
		overflow: hidden;
		width: 100%;
		height: 0px;
		margin: 0px;
		border: 0px;
	}
	.right{
		text-align: right;
	}
</style>

