
let _client = null;
let q = [];

export default {
	namespaced: false,
	state: {
		things: [{things:[]}],
		mans: [],
		links: [],
		index: {},
		index_things: {}
	},
	getters: {},
	actions: {
		INIT:async (context)=>{
			setInterval(function(){
				context.dispatch('login/WATCHDOG')
			}, 60*1000);
		},
		socket_mess: async(context, payload) => {
			let data  = payload.data[ 0 ];
			//context.dispatch('td/Accept_mess', data);
			if( data ){
				for( let pl of data ){
					if( pl.mutation ){
						context.commit( pl.mutation, pl.data );
					}else if(pl.action){
						context.dispatch( pl.action, pl.data )
					}
				}
			}
		},
		socket_connect: async(context, { client }) => {
			_client = client;
			console.log('A socket_connect');
		},
		postMessage( context, payload ) {
			if (!_client){
				q.push( payload )
				setTimeout(function(){
					context.dispatch('postMessage');
				}, 0);
			}else{
				while (q.length>0) _client.emit('mess', q.shift());
				if(payload) _client.emit('mess', payload);
			}
		},
		ERROR( context, payload ){
			alert( payload.message );
		},
		NEWFILE(context, {task}){
			if( task.argv.dst ) context.dispatch(task.argv.dst,  task);
		}
	},
	mutations: {
	},
};
