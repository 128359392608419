// VUE & Ko
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueSwal from 'vue-swal';

Vue.use(VueSwal);
import {store} from '../vue/store';

// Компоненты
import App from '../vue/components/app.vue';
import pub from '../vue/components/pub.vue';
import log from '../vue/components/log.vue';
import login from '../vue/components/login.vue';

// Прочее
import '../style/index.css';
import '../style/tabs.css';
import { directive as onClickaway } from 'vue-clickaway';

let router = new VueRouter({
	routes:[
		{ path: '/', redirect: '/pub' },
		{ path: '/pub', component: pub, props: true },
		{ path: '/log', component: log, props: true },
		{ path: '/login', component: login, props: true }
	]
});
//router.push().catch(() => {});

Vue.directive('on-clickaway', onClickaway);

//
// !!! Uncomment line below on production !!!
// Vue.config.productionTip = false
//

Vue.use(VueRouter);

new Vue({ render: h => h(App), store, router, components: { App }}).$mount('#el');

