import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

//import { createSocketioPlugin } from 'vuex-socketio-plugin'

Vue.use(Vuex);

export const store = new Vuex.Store({
	modules,
//	plugins: [ createSocketioPlugin('http://localhost:3000') ],
	strict: process.env.NODE_ENV !== 'production'
});



