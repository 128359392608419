var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "site" }, [_vm._m(0), _c("router-view")], 1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "site_header_main" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-2 text-center" }, [
          _c(
            "a",
            { staticClass: "site_header_main_title", attrs: { href: "/" } },
            [
              _c("img", {
                staticStyle: { height: "100px" },
                attrs: { src: "/img/6tv_logo.png" }
              })
            ]
          )
        ]),
        _c("div", { staticClass: "col-md-7 text-center" }, [
          _c("div", { staticClass: "city" })
        ]),
        _c("div", { staticClass: "col-md-3 text-center" }, [
          _c("div", { staticClass: "site_header_main_title" }, [
            _c("span", [_vm._v("+7 (4212) 41 70 80")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }