<template lang="pug">
.container
	.right
		a(href='/files')
			| старый интерфейс
	form.blank(v-on:submit.prevent='submit')
		.jumbotron.shadow(style='margin:100px auto; width: 450px;')
			.row
				label.col-md-3 Логин:
				.col-md-9
					input.form-control(v-model='login')
			.row
				label.col-md-3 Пароль:
				.col-md-9
					input.form-control(type='password', v-model='password')
			.row
				.col-md-12
					button.btn.btn-secondary.btn-sm(type='submit') войти
	//--
		.login_mess.shadow
		p кому "только загрузить" используйте
		p 
			span Пользователь: 
			b uploader
		p 
			span Пароль: 
			b 42



</template>

<script lang='js'>
	export default {
		created: function(){
			if(this.loggedin) this.$store.dispatch('login/LOGOUT');
			this.$store.dispatch('log/MESSAGE', { message:'Диалог входа начат' });
		},
		computed:{
			...mapFields(['login', 'password']),
			loggedin:{
				get(){
					return this.$store.getters['login/LOGGEDIN'];
				}
			},
			messages_length(){
				return this.$store.getters['log/MESSAGESLENGTH'];
			}
		},
		methods:{
			submit(){
				this.$store.dispatch('login/LOGIN');
			}
		},
		watch:{
			loggedin(newVal, oldVal) {
				if( newVal ){
					this.$router.push('/pub');
				}else{
					//this.$router.push('/login');
				}
			},
			messages_length(newVal, oldVal) {
				if( newVal>0 ){
					let mess = this.$store.getters['log/MESSAGE'];
					if( mess.type=='error' ){
						this.$swal(mess.title, mess.message, mess.type);
					}
				}
			}
		}
	}

	function mapFields(arr){
		let r = {};
		for( let f of arr ){
			r[f] = {
				get(){
					let value = this.$store.getters['login/ACCOUNT'][f]
					return value;
				},
				set( value ){
					return this.$store.commit('login/ACCOUNT', {f, value});
				}
			}
		}
		return r;
	}

</script>

<style>
	label{
		font-weight: 800;
		font-size: 18px;
		line-height: 35px;
	}
	.top10{
		margin-top: 10px;
	}
	.blank{
		background-color: transparent;
		margin: 0px;
		padding: 0px;
		border: 0px;
	}
	.login_mess{
		width: 450px;
		margin: 100px auto;
		padding: 50px;
	}
</style>

