export default {
	namespaced: true,
	state: {
		messages: []
	},
	getters: {
		MESSAGE: state => { return state.messages[0] },
		MESSAGES: state => { return state.messages },
		MESSAGESLENGTH:  state=> {
			return state.messages.length;
		},
		LOGIN: (state, getters, rootState, rootGetters) => {
			return rootGetters['login/ACCOUNT'].login || '-';
		}
	},
	mutations: {
		PUSHMESSAGE(state, data) {
			state.messages.unshift( data );
		},
	},
	actions: {
		ERROR: async  (context, {message='', title = "ВНИМАНИЕ ОШИБКА!", type='error'}) => {
			let login = context.getters['LOGIN'];
			context.commit( 'PUSHMESSAGE', {title, message, type, login, dt: new Date()} );
		},
		MESSAGE: async  (context, {message='', title = "К сведению", type='info'}) => {
			let login = context.getters['LOGIN'];
			context.commit( 'PUSHMESSAGE', {title, message, type, login, dt: new Date()} );
		},
	}
};

