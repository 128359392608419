<template lang="pug">
div
	.site
		.site_header_main
			.row
				.col-md-2.text-center
					a.site_header_main_title( href="/" )
						img(src='/img/6tv_logo.png', style='height:100px;')
				.col-md-7.text-center
					.city
				.col-md-3.text-center
					//--
						form(action='/search', style='margin-top:20px;')
						div#searchgroup
							input#search(type='text', placeholder='Поиск...', name='term')
							button#search
								span.glyphicon.glyphicon-search
					div.site_header_main_title
						span +7 (4212) 41 70 80
		
		router-view
</template>

<script>
	import mmenu from '../components/mmenu.vue';
	export default {
		components:{ mmenu },
		created:function(){
			this.$store.dispatch('INIT',{root:true});
		}
	}
</script>

<style>
	body{
		background: #e5e5e5 !important;
		color: #303030;
		font-size: 17px;
		margin: 0; 
		height: 100%; 
/*
		overflow-x: hidden;
		overflow-y: scroll;
*/
	}

	.site_header_main{
		background-color: #f5f6f7;;
		padding-top: 25px;
		padding-bottom: 0px;
		padding-left: 51px;
		padding-right: 51px;
		font-family: 'Oswald', sans-serif; 
		line-height: 48px !important;
		margin-bottom: 25px;
	}
	.site_header_main .city{
		border: solid red 0px;
		height: 130px;
		background-image: url(/img/city27.png);
		background-repeat: no-repeat;
	}
	.site_header_main_title h1{
		font-size: 40px;
		font-weight: bold;
		text-decoration: none;
		color: #dc553a;
	}
	.site_header_main_title,
	.site_header_main_title:hover {
		text-decoration: none;
		margin-left: 20px;
		color: #dc553a;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		border: none;
		font: normal 18px/1 Arial, Helvetica, sans-serif;
		color: #dc553a;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		text-shadow: 0 0 1px #fff ;
		margin-bottom: 10px;
	}
	.site {
		margin: 2em auto;
		padding: 0px;;
		max-width: 1280px;
		width: 100%;
		background: #fff;
		min-height: 1080px;
	}
h3 {
	color: #dc553a;
	/*
	color: #333;
	*/
	font-size: 28px;
	text-decoration: none;
	font-family: 'Oswald', sans-serif;
	line-height: 1;
}

</style>