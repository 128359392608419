var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("mmenu"),
      _c("h3", [_vm._v("Журнал сообщений")]),
      _c("div", [_vm._v(" ")]),
      _c(
        "tabs",
        { attrs: { activeTab: _vm.activeTab }, on: { newtab: _vm.newtab } },
        [
          _c("tab", { attrs: { name: "Журнал текущего сеанса" } }, [
            _c(
              "ul",
              _vm._l(_vm.$store.getters["log/MESSAGES"], function(rec) {
                return _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dt_h(rec.dt)) +
                      "  [ " +
                      _vm._s(rec.type) +
                      " ][ " +
                      _vm._s(rec.login) +
                      " ]  " +
                      _vm._s(rec.message)
                  )
                ])
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }