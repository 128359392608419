var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "sbmenu-catcher",
      on: {
        mouseover: function($event) {
          _vm.active = !_vm.active
        }
      }
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.closemenu,
            expression: "closemenu"
          }
        ],
        staticClass: "sbmenu",
        class: { sbmenuactive: _vm.active },
        on: {
          click: function($event) {
            _vm.active = false
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "sbmenu-btn pointer",
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.showmenu()
              }
            }
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.active,
                    expression: "!active"
                  }
                ],
                staticClass: "material-icons"
              },
              [_vm._v("menu")]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active,
                    expression: "active"
                  }
                ],
                staticClass: "material-icons"
              },
              [_vm._v("close")]
            )
          ]
        ),
        _c("div", { staticClass: "sbmenu-pad" }, [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mmenu,
                  expression: "mmenu"
                }
              ]
            },
            _vm._l(_vm.mmenu, function(mitem) {
              return _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: mitem.url, title: mitem.title } },
                    [_vm._v(_vm._s(mitem.caption))]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: mitem.mmenu,
                          expression: "mitem.mmenu"
                        }
                      ]
                    },
                    _vm._l(mitem.mmenu, function(mitem) {
                      return _c("li")
                    }),
                    0
                  )
                ],
                1
              )
            }),
            0
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }