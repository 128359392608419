var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upl" }, [
    _c("input", {
      ref: "filesselect",
      staticClass: "hidden",
      attrs: { type: "file", multiple: "multiple" },
      on: { change: _vm.showFile }
    }),
    _c("div", { attrs: { id: "file-drag-drop" } }, [
      _c(
        "form",
        {
          ref: "fileform1",
          staticClass: "form1",
          on: { click: _vm.showFilesSelectDialogue }
        },
        [_c("span", [_vm._v("файлы для загрузки бросать здесь ")])]
      ),
      _c(
        "form",
        {
          ref: "fileform2",
          staticClass: "form2",
          on: { click: _vm.showFilesSelectDialogue }
        },
        [_c("span", [_vm._v("или кликнуть здесь и выбрать")])]
      ),
      _c(
        "div",
        { ref: "upl_ul", staticClass: "upl_ul" },
        _vm._l(_vm.$store.getters["fuploader/GETQ"], function(t) {
          return _c("div", { staticClass: "upl_li" }, [
            _c("div", { staticClass: "upl_li_caption" }, [
              _c("div", { staticClass: "upl_li_caption_img" }, [
                _c("img", {
                  key: "img" + t.id,
                  ref: "preview" + t.id,
                  refInFor: true,
                  staticClass: "preview"
                })
              ]),
              _c("div", { staticClass: "upl_li_caption_text" }, [
                _vm._v(
                  "⊳ \n" +
                    _vm._s(t.file.name) +
                    "  ( " +
                    _vm._s(Math.round(t.file.size / (1024 * 1024))) +
                    " Mb ) [" +
                    _vm._s(t.i) +
                    " of " +
                    _vm._s(t.file.size) +
                    "] " +
                    _vm._s(Math.round((t.i / t.file.size) * 100)) +
                    "%"
                )
              ])
            ]),
            _c("div", {
              staticClass: "upl_li_bg",
              style: "width:" + Math.round((t.i / t.file.size) * 100) + "%"
            }),
            _c("div", { staticClass: "upl_li_ctl" }, [
              t.done == "none"
                ? _c("div", { staticClass: "upl_li_pc none" }, [_vm._v("...")])
                : _vm._e(),
              t.done == "progress"
                ? _c("div", { staticClass: "upl_li_pc pr" }, [_vm._v("↺")])
                : _vm._e(),
              t.done == "paused"
                ? _c("div", { staticClass: "upl_li_pc pr" }, [_vm._v("P")])
                : _vm._e(),
              t.done == "done"
                ? _c("div", { staticClass: "upl_li_pc done" }, [_vm._v("✓")])
                : _vm._e(),
              t.done == "err"
                ? _c("div", { staticClass: "upl_li_pc err" }, [_vm._v("Χ")])
                : _vm._e(),
              t.done == "fordelete"
                ? _c("div", { staticClass: "upl_li_pc err" }, [_vm._v("Χ")])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger btn-xs",
                  on: {
                    click: function($event) {
                      return _vm.stopTask(t)
                    }
                  }
                },
                [_vm._v("×")]
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }