<template lang="pug">
div
	.sbmenu-catcher(v-on:mouseover='active=!active')
	.sbmenu( v-bind:class='{sbmenuactive:active}', v-on:click='active=false', v-on-clickaway="closemenu" )
		.sbmenu-btn.pointer(v-on:click.stop.prevent='showmenu()')
			span.material-icons(v-show='!active') menu
			span.material-icons(v-show='active') close
		.sbmenu-pad
			ul(v-show="mmenu")
				li(v-for="mitem in mmenu")
					router-link(v-bind:to="mitem.url", v-bind:title="mitem.title") {{mitem.caption}}
					ul(v-show="mitem.mmenu")
						li(v-for="mitem in mitem.mmenu")
</template>

<script lang='js'>
	export default {
		data: function(){
			return {
				active: false,
				mmenu: [
					{"url":"/pub","title":"Файлы", caption:"Файлы","rights":3},
					{"url":"/log","title":"Журнал", caption:"Журнал","rights":3},
					{"url":"/login","title":"Выход", caption:"Выход","rights":3}
				],
				sbmenuactive: true
			}
		},
		methods:{
			showmenu(){
				this.active=!this.active;
			},
			closemenu(){
				this.active=false;
			}
		}
	}
</script>

<style>

.sbmenu {
	position: fixed;
	width: 250px;
	top:15px;
	left:0px;
	transition: 0.3s;
	transform: translateX(-100%);
	z-index:1;
}

.sbmenuactive{
	transform: translateX(0%);
}

.sbmenu-btn{
	width:35px;
	height: 30px;
	background-color: #fff;
	color:  #ddd;
	position: absolute;
	top: 0px;
	right: -34px;
	font-size: 20px;
	text-align: center;
	border-radius: 0px 5px 5px 0px;
	line-height: 40px;
	border-top: solid #ccc 1px;
	border-right: solid #ccc 1px;
	border-bottom: solid #ccc 1px;
}

.sbmenu .sbmenu-pad{
	border: solid #ccc 1px;
	background-color: #fff;
	border-radius: 0px 0px 5px 0px;
	padding: 20px;
	overflow: hidden;
}

.sbmenu ul{
	list-style-type: none;
	margin: 0;
	margin-left: 20px;
	padding: 0;
}

.sbmenu ul li a, .sbmenu ul li span{
	font-family: 'Roboto Slab', "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
	font-size: 16px;
	line-height: 30px;
	color:  #2c8ebb;
}

.sbmenu ul li a{
	cursor: pointer;
}

.sbmenu-catcher{
	width:2px;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
	border: solid red 0px;
	z-index:3;
}
</style>
