import SparkMD5 from 'spark-md5';

export default {
	namespaced: true,
	state: {
		loggedin: false,
		account:{
			login: '',
			password: ''
		}
	},
	getters: {
		LOGGEDIN: state => { return state.loggedin },
		ACCOUNT: state => { return state.account }
	},
	mutations: {
		ACCOUNT( state, { f='', value='' } ){
			state.account[f] = value;
		},
		LOG_IN( state ){
			state.loggedin = true;
		},
		LOG_OUT( state ){
			state.loggedin = false;
			state.account.login = '';
			state.account.password = '';
		}
	},
	actions: {
		WATCHDOG: async  (context, err) => {
			if( (context.getters['ACCOUNT'].idsess || '').length>0 ){
				await axios.post( '/api/watchdog', { idsess:context.getters['ACCOUNT'].idsess });
			}
		},
		ERROR: async  (context, err) => {
			context.dispatch('log/MESSAGE', {title:"ВНИМАНИЕ ОШИБКА!", message:err.message, type:'error'}, {root:true});
		},
		MESSAGE: async  (context, payload) => {
			context.dispatch('log/ERROR', {title:"К сведению", message:payload.message, type:'info'}, {root:true});
		},
		LOGOUT: async  (context, payload) => {
			await api(context, '/api/logout');
			context.dispatch('MESSAGE', {message:`Успешный выход`});
			context.commit('LOG_OUT');
		},
		LOGIN: async  (context, payload) => {
			let acc = context.getters['ACCOUNT'];
			try{
				let res1 = await axios.post( '/api/prelogin', { login:acc.login });
				context.commit('ACCOUNT', {f:'idsess', value:res1.data.idsess});
				acc = context.getters['ACCOUNT'] // ??
				let factor = SparkMD5.hash(res1.data.some + SparkMD5.hash( acc.login + acc.password ));
				let res2 = await api( context, '/api/login', { factor });
				if( res2.ok ){
					context.commit('LOG_IN');
					context.dispatch('MESSAGE', {message:`Успешный вход`});
				}else{
					context.commit('LOG_OUT');
					context.dispatch('ERROR', {message:`Неверный пароль пользователя ${acc.login}`});
				}
			}catch(err){
				context.commit('LOG_OUT');
				context.dispatch('ERROR', { message:err.data });
			}
		}
	}
};

async function api(context, url, data){
	let acc = context.rootGetters['login/ACCOUNT'];
	let idsess = ((acc || {}).idsess || '-');
	let res;
	let config = {
		headers: {
			"x-pub-settv-ru-sessionid": idsess,
		}
	}
	res = await axios.post(  url, data, config );
	return res.data;
}
