<template lang="pug">
div(v-show="isActive")
	slot
</template>

<script lang='js'>
	export default {
		data(){
			return {
				isActive: false
			}
		},
		props: {
			name: { required: true },
			selected: { default: false}
		},
		computed: {
			href() {
				return '#' + this.name.toLowerCase().replace(/ /g, '-');
			}
		},
		mounted() {
			this.isActive = this.selected;
		}
	}
</script>
