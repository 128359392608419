export default {
	namespaced: true,
	state: {
		path: [],
		files: [{name:'',showurl:false, stats:{size:0}}],
		folders: []
	},
	getters: {
		PATH: state => { return state.path },
		FILES: state => { return state.files },
		FOLDERS: state => { return state.folders }
	},
	mutations: {
		LOAD( state, { path=null, files=null, folders=null } ){
			if( path!=null ){
				while(state.path.length>0) state.path.shift();
				for( let p of path) state.path.push( p );
			}
			if( files!=null ){
				while(state.files.length>0) state.files.shift();
				for( let f of files ){
					f.key = Math.round(Math.random()*1000000000);
					state.files.push( f );
				}
			}
			if( folders!=null ){
				while(state.folders.length>0) state.folders.shift();
				for( let f of folders) state.folders.push( f );
			}
		},
		CD( state, { name=null, level=null } ){
			if( name ){
				state.path.push(name);
			}else if(level!=null){
				if(level>=0){
					let a = state.path.splice( 0, level );
					while(state.path.length>0) state.path.shift();
					while( a.length>0 ) state.path.push(a.shift());
				}else{
					if(state.path.length>0){
						state.path.pop();
					}
				}
			}
		},
		ADDFILE( state, { files=[] } ){
			while( files.length > 0 ){
				let file = files.shift();
				state.files.push( file );
			}
		},
		MKDIR( state, { folders=[] } ){
			while( folders.length > 0 ){
				let folder = folders.shift();
				state.folders.push( folder );
			}
		},
		YT_DLP( state, { } ){
		},
		RMDIR( state, { name=null } ){
			if( name ){
				let i
				for( i in state.folders){
					let folder = state.folders[i];
					if( folder.name == name ){
						break;
					}
				}
				if(i<state.folders.length){
					state.folders.splice(i, 1);
				}
			}
		},
		RMFILE( state, { name=null } ){
			if( name ){
				let i
				for( i in state.files){
					let file = state.files[i];
					if( file.name == name ){
						break;
					}
				}
				if( i<state.files.length ){
					state.files.splice(i, 1);
				}
			}
		},
		MVDIR( state, { name=null, newname=null, mtime=null } ){
			for( let folder of state.folders){
				if( folder.name == name ){
					folder.name = newname;
					folder.mtime = mtime;
					break;
				}
			}
		},
		MVFILE( state, { name=null, newname=null, mtime=null } ){
			for( let file of state.files){
				if( file.name == name ){
					file.name = newname;
					file.mtime = mtime;
					break;
				}
			}
		},
		SHOWURL( state, { name=null } ){
			if( name ){
				for( let file of state.files){
					if( file.name == name ){
						file.showurl = true;
						file.key = Math.round(Math.random()*1000000000);
						break;
					}
				}
			}
		},
		HIDEURL( state, { name=null } ){
			if( name ){
				for( let file of state.files){
					if( file.name == name ){
						file.showurl = false;
						file.key = Math.round(Math.random()*1000000000);
						break;
					}
				}
			}
		}
	},
	actions: {
		ERROR: async  (context, err) => {
			context.dispatch('log/MESSAGE', {title:"ВНИМАНИЕ ОШИБКА!", message:err.message, type:'error'}, {root:true});
		},
		MESSAGE: async  (context, payload) => {
			context.dispatch('log/MESSAGE', {title:"К сведению", message:payload.message, type: 'info'}, {root:true});
		},
		ALERT: async  (context, payload) => {
			context.dispatch('log/MESSAGE', {title:"Уважаемый пользователь!", message:payload.message, type: 'alert'}, {root:true});
		},
		LOAD: async  (context, payload) => {
			let result = await api(context,  '/api/load', { path: context.getters['PATH'] });
			context.commit('LOAD',  result );
		},
		CD: async (context, payload) => {
			context.commit('CD',  payload );
			context.dispatch('LOAD');
		},
		SHOWURL: async (context, payload) => {
			context.commit('SHOWURL',  payload );
		},
		HIDEURL: async (context, payload) => {
			context.commit('HIDEURL',  payload );
		},
		ADDFILE: async (context, payload) => {
			try{
				let res = await api(context,  '/api/confirmdownloads', { path: context.getters['PATH'], name: payload.file.name, id: payload.id, md5: payload.md5 });
				let filename = res.files[0].name;
				if( res.ok ){
					context.commit('ADDFILE',  {files:res.files} );
					context.dispatch('MESSAGE', { message: 'Файл ' + localname(context.getters['PATH'], filename) + ' загружен. Зафиксирована скорость: ' + payload.bps + 'MB/sec' });
				}
			}catch(err){
				context.dispatch('fuploader/STOPTASK', payload, {root:true});
				context.dispatch('ERROR', { message: err.data || err.message});
			}
		},
		MKDIR: async (context, {name=null}) => {
			if( name != null ){
				try{
					let res = await api(context,  '/api/mkdir', { path: context.getters['PATH'], name });
					context.commit('MKDIR',  res );
					
					context.dispatch('MESSAGE', { message: 'Создана папка ' + localname(context.getters['PATH'], name) });
				}catch(err){
					context.dispatch('ERROR', { message: err.data});
				}
			}
		},
		YT_DLP: async (context, {url=null}) => {
			if( url != null ){
				try{
					let res = await api(context,  '/api/yt_dlp', { path: context.getters['PATH'], url });
					context.dispatch('ALERT', { message: 'Ссылка поставлена в очередь на закачку в папку:' + localname(context.getters['PATH'], "") });
					//context.commit('YT_DLP',  res );
				}catch(err){
					context.dispatch('ERROR', { message: err.data});
				}
			}
		},
		RM: async (context, {name=null}) => {
			if( name != null ){
				try{
					let res = await api(context,  '/api/rm', { path: context.getters['PATH'], name });
					if( res.ok ){
						if(res.isDirectory){
							context.commit('RMDIR',  {name} );
							context.dispatch('MESSAGE', { message: 'Удалена папка ' + localname(context.getters['PATH'], name)});
						}else{
							context.commit('RMFILE',  {name} );
							context.dispatch('MESSAGE', { message: 'Удален файл ' + localname(context.getters['PATH'], name)});
						}
					}
				}catch(err){
					context.dispatch('ERROR', { message: err.data});
				}
			}
		},
		MV: async (context, {name=null, newname=null, moveiffolder=false}) => {
			if( (name!=null) && (newname!=null) ){
				let res;
				try{
					res = await api(context,  '/api/mv', { path: context.getters['PATH'], name, newname, moveiffolder });
					if( res.ok ){
						if(res.isDirectory){
							context.commit('MVDIR',  { name, newname, mtime: res.stats.mtime } );
							context.dispatch('MESSAGE', { message: 'Переименована папка ' + localname(context.getters['PATH'], name) + ' -> "./' + newname + '"'});
						}else{
							if( moveiffolder ){
								context.commit('RMFILE',  { name } );
								context.dispatch('MESSAGE', { message: 'Переименован файл ' + localname(context.getters['PATH'], name) + ' -> "./' + newname + '/' + name + '"'});
							}else{
								context.commit('MVFILE',  { name, newname, mtime: res.stats.mtime } );
								context.dispatch('MESSAGE', { message: 'Переименован файл ' + localname(context.getters['PATH'], name) + ' -> "./' + newname + '"'});
							}
						}
					}
				}catch(err){
					context.dispatch('ERROR', { message: err.data });
				}
			}
		}
	}
};

async function api(context, url, data){
	let acc = context.rootGetters['login/ACCOUNT'];
	let idsess = ((acc || {}).idsess || '-');
	let config = {
		headers: {
			"x-pub-settv-ru-sessionid": idsess,
		}
	}
	let res = await axios.post(  url, data, config );
	return res.data;
}

function localname(path, name){
	return '"' + ('/' + path.join('/') + '/' + name).replace(/\/\//,'/') + '"';
}