const blocksize = 2 * 1024 * 1024;

let rnd = 0;
let components = [];
let deffered = null;
import SparkMD5 from 'spark-md5';

export default {
	namespaced: true,
	state: {
		q: [],
		qlen: 0 
	},
	getters: {
		QLEN: state => {
			return state.q.length;
		},
		GETQ: state => {
			return state.q;
		},
		getQarr: state => {
			return state.q;
		}
	},
	mutations: {
		INIT( state, src ){
			components.push( src );
		},
		PUSHTASK( state, task ){
			state.q.push( task );
			state.qlen = state.q.length;
		},
		RMTASK( state, task ){
			state.q = state.q.filter( t => {
				return (task.id != t.id);
			});
		},
		SETTASKPAR( state, {task, par, val} ){
			task[par] = val;
		},
		SETSTATUS( state, {task, status} ){
			task.done = status;
		},
		INCERRCOUNT( state, {task, status} ){
			task.errcount = (task.errcount || 0) + 1;
		}
	},
	actions: {
		ERROR: async  (context, err) => {
			context.dispatch('log/MESSAGE', {title:"ВНИМАНИЕ ОШИБКА!", message:err.message, type:'error'}, {root:true});
		},
		MESSAGE: async  (context, payload) => {
			let message = ( typeof(payload)=='string' )?payload:payload.message || '';
			context.dispatch('log/MESSAGE', {title:"К сведению", message, type:'info'}, {root:true});
		},
		STOPTASK: async ( context, task ) => {
			if(task.status !='fordelete'){
				context.commit('SETSTATUS', {task, status:'fordelete'});
				if( !deffered ) deffered = setTimeout( getTick(context), 0);
			}
		},
		RMTASK: async ( context, task ) => {
			await deleteFile( context, task );
		},
		FILE_ADD: async ( context, {file, argv} ) => {
			if( file ) {
				let id = (1*( new Date() )).toString() + ('0000' + (rnd++).toString()).substr(-5);
				let task = {
					id,
					file,
					argv: (argv || {}),
					i: 0,
					md5i: -1,
					startmoment: null,
					totaltransfered: 0,
					bps: 0,
					done: 'none'
				};
				context.commit('PUSHTASK', task);
				return id;
			}
		},
		START: async ( context ) => {
			if( !deffered ) deffered = setTimeout( getTick(context), 0);
			//setTimeout( getTick(context), 0);
			
		},
		GETIMAGEPREVIEWS: async ( context ) => {
			components.forEach( component =>{ component.getImagePreviews() });
		}
	}
};

function getTick( context ){
	return async function(){
		deffered = null;
		let q = context.getters.GETQ;
		if( q.length>0 ){
			let flag = false;
			for(let task of q ){
				if( task.done == 'none' ){
					await createFile( context, task );
					flag = true;
					break;
				}else if( task.done == 'progress' ){
					await sendChunk( context, task );
					flag = true;
					break;
				}else if( task.done == 'paused' ){
					flag = true;
					if( !task.busy ){
						if(task.waitcounter>400){
							context.commit('SETSTATUS', {task, status:'progress'});
							await sendChunk( context, task );
						}else{
							context.commit('SETTASKPAR', {task, par:'waitcounter', val:task.waitcounter+1});
							task.waitcounter+1;
						}
						break;
					}
				}else if( (task.done == 'fordelete') || (task.done == 'done') || (task.done == 'err') ){
					flag = true;
					if( !task.busy ){
						task.waitcounter = task.waitcounter || 0;
						if(task.waitcounter>400){
							context.dispatch('RMTASK', task);
							break;
						}else{
							task.waitcounter++;
						}
					}
				}
			}
			if( flag && (!deffered) ) deffered = setTimeout( getTick(context), 10);
		}
	};
}

async function deleteFile( context, task ){
	if(task.busy) return;
	task.busy = true;
	if( (task.done == 'fordelete') || (task.done == 'err') ){
		try{
			await axios.post( '/delfile?id=' + task.id );
		}catch(e){}
	}
	context.commit('RMTASK', task);
	task.busy = false;
	context.dispatch('GETIMAGEPREVIEWS');
}

async function createFile(context, task ){
	if(task.busy) return;
	task.busy = true;
	try{
		task.spark = new SparkMD5.ArrayBuffer();
		task.totaltransfered = 0;
		if( (await axios.post( '/createfile?size=' + task.file.size + '&id=' + task.id, '', {headers:{'Content-Type':'text/plain'}} )).data.match(/^ok/) ){
			context.commit('SETSTATUS', { task, status:'progress' });
		}else{
			context.commit('SETSTATUS', { task, status:'err' });
		}
	}catch(e){
		context.commit('SETSTATUS', { task, status:'err' });
	}
	task.busy = false;
}

async function sendChunk(context, task ){
	if( 1*task.i < 1*task.file.size ){
		if(task.busy) return;
		task.busy = true;
		try{
			let delta = ((task.file.size - task.i) < blocksize)?(task.file.size - task.i):blocksize;
			let blob = task.file.slice( task.i, task.i + delta );
			if( task.i == 0 ) context.commit('SETTASKPAR', {task, par:'startmoment', val: new Date()});
			
			let results = await Promise.all([
				sendData(task, delta, blob),
				appendBinaryToMD5( context, task, blob )
			]);
			//console.log(results[0].data , results[1]);
			if( results[0].data == results[1] ){
				let totaltransfered = task.totaltransfered + delta;
				let bps = (Math.round( totaltransfered / ( (new Date() - task.startmoment)/1000 ) ) / ( 1024*1024 )).toFixed( 2 ) ;
				let i = 1*task.i + 1*delta;
				context.commit('SETTASKPAR', {task, par:'i', val:i});
				context.commit('SETTASKPAR', {task, par:'totaltransfered', val:totaltransfered});
				context.commit('SETTASKPAR', {task, par:'bps', val:bps});
				context.commit('SETTASKPAR', {task, par:'errcount', val:0});
				context.commit('SETTASKPAR', {task, par:'waitcounter', val:0});
			}else{
				throw new Error('Сервер не вернул подтерждение');
			}
		}catch(e){
			console.log(e)
			if( task.errcount >= 30 ){
				context.commit('SETSTATUS', {task, status:'err'});
				context.dispatch('ERROR', {message:'Загрузка файла "' + task.file.name + '" отменена из-за повторяющихся проблем передачи'});
			}else{
				context.commit('SETSTATUS', {task, status:'paused'});
				context.commit('INCERRCOUNT', {task});
				context.dispatch('MESSAGE', 'Проблемы передачи "' + task.file.name + '":  ' + (e.message || (' запрос [' + e.config.url + '] вернул Status=' + e.status + ' - ' + (e.statusText + ((e.statusText.toLowerCase()==e.data.toLowerCase())?'':': ' + e.data)))));
				context.commit('SETTASKPAR', {task, par:'waitcounter', val:0});
			}
		}
		task.busy = false;
	}else{
		//context.commit('SETMD5', {task, md5:task.spark.end() });
		context.commit('SETTASKPAR', {task, par:'md5', val:task.spark.end()});
		context.commit('SETSTATUS', {task, status:'done'});
		task.spark.destroy();
		try{
			context.dispatch('NEWFILE', { task }, {root:true});
		}catch(e){}
	}
}

function sendData(task, delta, blob){
	return new Promise((resolve, reject)=>{
		let formData = new FormData();
		formData.append( 'file', blob );
		formData.append( 'offset', task.i );
		//await fileReader( task, blob );
		//reject(new Error('fc'))
		//  (task.errcount>0?'':'3') +
		axios.post(
			'/uploadfile' +
			'?start=' + task.i +
			'&size=' + delta +
			'&id=' + task.id, 
			formData, { headers: {'Content-Type': 'multipart/form-data'}, timeout: 5000 }
		).then(resolve, reject);
	});
}

function appendBinaryToMD5(context, task, blob){
	return new Promise((resolve, reject)=>{
		let spark = new SparkMD5.ArrayBuffer();
		const reader = new FileReader();
		reader.addEventListener("loadend", function() {
			spark.append(reader.result);
			let md5 = spark.end();
			spark.destroy();
			if( task.i != task.md5i ){
				task.spark.append(reader.result);
				context.commit('SETTASKPAR', {task, par:'md5i', val:task.i});
			}
			resolve(md5);
		});
		reader.addEventListener("error", function(err) {
			reject(err);
		});
		reader.readAsArrayBuffer( blob );
	});
}

/*
async function fileReader( task, buff ){
	while(true){
		if( (task.FileReader.readyState==0) || (task.FileReader.readyState==2)){
			task.FileReader.readAsBinaryString( buff );
			break;
		}else{
			await sleep(100);
		}
	}
}
*/

async function sleep(msec){
	return new Promise( function(resolve, reject){
		setTimeout(resolve, msec);
	});
}